var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex flex-column flex-1",attrs:{"elevation":"2","color":"background"}},[_c('v-card-title',[_vm._v(" انواع القوائم "),_c('v-spacer'),_c('DefaultDialog',{attrs:{"buttonText":"اضافة","title":"اضافة نوع","openToEdit":_vm.openToEdit,"editTitle":"تعديل نوع","openToView":_vm.openToView,"viewTitle":"عرض البيانات","hideDetailOnLoading":false,"showAddButton":_vm.isCanCreate},on:{"onCloseDialog":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"default",fn:function({
          submitWithClose,
          submitWithNew,
          submitWithoutClose,
          closeDialog,
          isEdit,
          isView,
          isNew,
        }){return _c('NavTypeDetail',{attrs:{"submitWithClose":submitWithClose,"submitWithNew":submitWithNew,"submitWithoutClose":submitWithoutClose,"setItemID":_vm.setItemID,"closeDialog":closeDialog,"afterSubmit":_vm.getNavTypes,"itemId":_vm.itemId,"isEdit":isEdit,"isView":isView,"isNew":isNew}})}}])})],1),_c('v-card-text',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('languages-filter',{on:{"change":_vm.changeLanguage}})],1)],1),(_vm.$store.state.loading.show)?_c('v-row',[_c('v-flex',{attrs:{"xs6":"","md4":"","lg4":"","xl2":""}},[_c('v-skeleton-loader',{attrs:{"type":"article"}})],1)],1):_c('v-row',{staticClass:"flex-grow-0",attrs:{"dense":""}},_vm._l((_vm.navTypes),function(item,index){return _c('v-flex',{key:index,attrs:{"xs6":"","md4":"","lg4":"","xl2":""}},[_c('NavTypeCard',{attrs:{"item":item},on:{"viewRow":function($event){return _vm.viewRow(item)},"editRow":function($event){return _vm.editRow(item)},"deleteRow":function($event){return _vm.deleteRow(item)}}})],1)}),1),_c('v-spacer'),_c('v-row',{staticClass:"justify-center flex-grow-0 my-1"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.numberOfPages},model:{value:(_vm.options.pageIndex),callback:function ($$v) {_vm.$set(_vm.options, "pageIndex", $$v)},expression:"options.pageIndex"}}),_c('v-select',{staticClass:"flex-grow-0 d-flex align-center",staticStyle:{"width":"100px"},attrs:{"items":[5, 10, 15, 20],"label":"عدد الصفوف","solo":"","dense":"","hide-details":"","item-color":"white"},model:{value:(_vm.options.pageSize),callback:function ($$v) {_vm.$set(_vm.options, "pageSize", $$v)},expression:"options.pageSize"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }