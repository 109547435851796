var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultDialog',{attrs:{"width":"50%","height":"40%","buttonText":"القائمة","title":"اضافة قائمة","openToEdit":_vm.openToEdit,"editTitle":"تعديل قائمة","openToView":_vm.openToView,"viewTitle":"عرض البيانات","customAddButton":true},on:{"onCloseDialog":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"addButton",fn:function({ on, attrs, click }){return [_vm._t("addButton",null,{"attrs":attrs,"on":on,"click":click})]}},{key:"default",fn:function({
      submitWithClose,
      submitWithNew,
      submitWithoutClose,
      closeDialog,
      isEdit,
      isView,
      isNew,
    }){return _c('NavigationShortDetail',{attrs:{"submitWithClose":submitWithClose,"submitWithNew":submitWithNew,"submitWithoutClose":submitWithoutClose,"setItemID":_vm.setItemID,"closeDialog":closeDialog,"isEdit":isEdit,"isView":isView,"isNew":isNew,"afterSubmit":_vm.afterSubmit,"itemId":_vm.itemId,"typeId":_vm.typeId,"parentId":_vm.parentId}})}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }