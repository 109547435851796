<template>
  <v-card
    :color="config.color"
    class="d-flex flex-column ma-1"
    :style="config.style"
    elevation="1"
    dark
  >
    <v-card-title class="text-h5" v-text="subtitle" />
    <v-card-subtitle v-text="item.slug" />
    <v-spacer />
    <v-card-actions>
      <v-btn
        small
        color="#78b200"
        @click="viewRow"
        class="mr-1"
        v-if="isCanRead"
      >
        <v-icon color="#fff">mdi-eye</v-icon>
      </v-btn>
      <v-btn
        small
        color="#ffa000"
        @click="editRow"
        class="mr-1"
        v-if="isCanUpdate"
      >
        <v-icon color="#fff">mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn
        small
        color="pink"
        @click="deleteRow"
        class="mr-1"
        v-if="isCanDelete"
      >
        <v-icon color="#fff">mdi-delete</v-icon>
      </v-btn>

      <NavTypeNavigationsBtn
        :navTypeId="navTypeId"
        label=""
        :isSmall="true"
        clss="mr-1"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import PermissionMixin from '@/mixins/PermissionMixin';
import NavTypeNavigationsBtn from './NavTypeNavigationsBtn.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [PermissionMixin(appPermissions.navTypes)],
  computed: {
    subtitle() {
      return this.item?.language?.name || '';
    },
    navTypeId() {
      return this.item.id || 0;
    },
  },
  data: () => ({
    config: {
      color: '#1F7087',
      style: {
        minHeight: '150px',
      },
    },
  }),
  methods: {
    viewRow() {
      this.$emit('viewRow');
    },
    editRow() {
      this.$emit('editRow');
    },
    deleteRow() {
      this.$emit('deleteRow');
    },
  },
  components: { NavTypeNavigationsBtn },
};
</script>

<style scoped lang="scss"></style>
