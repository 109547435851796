import { getErrorDetails } from '@/helper/commonjs';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';

export default () => ({
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'itemId',
    'item',
  ],
  methods: {
    doSave() {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      const postData = { ...this.postedData() };
      const axios = this.$http;

      var resultPromise = this.isNew
        ? axios.post(this.crudOptions.postUrl, postData)
        : axios.put(this.crudOptions.putUrl, postData);

      return resultPromise
        .then(this.responseHandler)
        .catch(this.errorHandler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    responseHandler(response) {
      if (response.status != 200 && response.status != 204) return false;

      showSnackbarSuccessMessage('تم الحفظ بنجاح');
      this.setItemID(response.data.id);
      this.creationTime = response.data.creationTime;
      this.lastModificationTime = response.data.lastModificationTime;
      return true;
    },
    errorHandler(error) {
      const response = error.response || {};
      const responseData = response.data || {};
      const { status, details, message } = getErrorDetails(error);

      if (this.onCreateUpdateError) {
        this.onCreateUpdateError({ details, code, message }, responseData);
        return;
      }

      if (status == 400 && details)
        return showSnackbarFailMessage(message || details);
      showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    showInvalidMsg() {
      showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
    },
    doSubmit(cb) {
      if (this.isInvalid()) return this.showInvalidMsg();
      this.doSave().then((state) => {
        if (!state) {
          showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
          return;
        }
        cb?.();
      });
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.setItemID(null);
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
  },
});
