var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isCanRead)?_c('DefaultDialog',{attrs:{"width":"65%","height":"85vh","buttonText":"القوائم","title":"القوائم المرتبطة بالنوع","openToEdit":_vm.openToEdit,"editTitle":"تعديل القوائم","openToView":_vm.openToView,"viewTitle":"عرض البيانات","customSaveButton":true,"customAddButton":true},on:{"onCloseDialog":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"saveButton",fn:function({ on, attrs, click }){return [_vm._t("saveButton",null,{"attrs":attrs,"on":on,"click":click})]}},{key:"addButton",fn:function({ on, attrs, click }){return [_vm._t("addButton",null,{"attrs":attrs,"on":on,"click":click})]}},{key:"default",fn:function({
      submitWithClose,
      submitWithNew,
      submitWithoutClose,
      closeDialog,
      isEdit,
      isView,
      isNew,
    }){return _c('NavTypeNavigationsTree',{attrs:{"submitWithClose":submitWithClose,"submitWithNew":submitWithNew,"submitWithoutClose":submitWithoutClose,"setItemID":_vm.setItemID,"closeDialog":closeDialog,"isEdit":isEdit,"isView":isView,"isNew":isNew,"afterSubmit":_vm.afterSubmit,"itemId":_vm.itemId,"navTypeId":_vm.navTypeId}})}}],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }