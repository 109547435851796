<template>
  <NavTypeNavigationsTreeDialog :navTypeId="navTypeId" v-model="model">
    <template #addButton="{ on, attrs, click }">
      <v-btn
        :large="!buttonIsSmall"
        :small="buttonIsSmall"
        :class="buttonClass"
        color="primary"
        elevation="1"
        v-on="on"
        @click="click"
        :attrs="attrs"
        :loading="$store.state.loading.show"
      >
        <v-icon>mdi-menu-open</v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
  </NavTypeNavigationsTreeDialog>
</template>

<script>
import NavTypeNavigationsTreeDialog from '../dialog/NavTypeNavigationsTreeDialog.vue';

export default {
  props: ['navTypeId', 'label', 'clss', 'isSmall'],
  components: { NavTypeNavigationsTreeDialog },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    buttonText() {
      if (this.label == undefined) return 'تعديل القوائم المرتبطة بالنوع';
      return this.label;
    },
    buttonClass() {
      if (this.clss == undefined) return 'mt-5';
      return this.clss;
    },
    buttonIsSmall() {
      if (this.isSmall == undefined) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
