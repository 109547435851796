import DefaultDialog from '@/components/DefaultDialog.vue';

export default () => ({
  props: ['afterSubmit', 'value'],
  components: { DefaultDialog },
  data() {
    return {
      openToEdit: false,
      openToView: false,
      itemId: null,
      item: null,
    };
  },
  computed: {
    dialogSettings: {
      get() {
        return this.value;
      },
      set($this) {
        this.$emit('input', $this);
      },
    },
  },
  methods: {
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
      this.item = item;
    },
    viewRow(item) {
      this.openToView = true;
      this.itemId = item.id;
      this.item = item;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
  },
  created() {
    this.dialogSettings = this;
  },
});
