<template>
  <form class="my-5">
    <!-- 
      <v-container fluid> </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h1>القائمة</h1>
          </v-col>
        </v-row>
      </v-container> 
    -->

    <v-progress-linear
      indeterminate
      color="green darken-2"
      :active="isLoading"
    />
    <v-card elevation="1">
      <!-- TABS HEADER -->

      <v-tabs
        show-arrows
        background-color="indigo"
        dark
        v-model="tab"
        align-with-title
        fixed-tabs
        height="45px"
        class="mb-4"
      >
        <v-tabs-slider color="primary darken-1"></v-tabs-slider>
        <v-tab
          v-for="lang in languagesKeysValue"
          :key="lang.key"
          class="grey lighten-3 black--text"
          active-class="primary lighten-1 white--text font-weight-black "
        >
          {{ lang.value }}
        </v-tab>
      </v-tabs>

      <!-- TABS CONTENT -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
          <v-card flat>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pb-0 mb-0">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="formLanguage[`${lang.key}`][`name`]"
                    :counter="50"
                    :label="'الاسم بالـ ' + lang.value"
                    outlined
                    :disabled="!isEdit && !isNew"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        dense
                        hide-details="auto"
                        :disabled="!isEdit && !isNew"
                        v-model="formLanguage[`${lang.key}`][`url`]"
                        outlined
                        label="الرابط"
                        class="link-input"
                        required
                    /></v-col>
                    <v-col cols="4">
                      <v-select
                        :disabled="!isEdit && !isNew"
                        :loading="isLoading"
                        :items="Object.keys(urlType)"
                        v-model="formLanguage[`${lang.key}`][`urlType`]"
                        outlined
                        label="نوع الرابط"
                        placeholder="نوع الرابط"
                        dense
                        hide-details="auto"
                        item-text="key"
                        item-value="urlType[key]"
                        item-color="white"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :disabled="!isEdit && !isNew"
                    :loading="isLoading"
                    :items="targetItems"
                    v-model="formLanguage[`${lang.key}`][`target`]"
                    outlined
                    label="target"
                    placeholder="target"
                    dense
                    hide-details="auto"
                    item-color="white"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :disabled="!isEdit && !isNew"
                    v-model="formLanguage[`${lang.key}`][`icon`]"
                    outlined
                    label="icon"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card elevation="0" outlined height="160">
                    <v-card-text>
                      <v-row class="align-end justify-space-between">
                        <v-col>
                          <UploadImage
                            v-if="isEdit || isNew"
                            v-model="formLanguage[`${lang.key}`][`image`]"
                            :selectedLajnguage="lang.key"
                            :multiple="false"
                            text="الصورة"
                          />
                        </v-col>
                        <v-col
                          cols="5"
                          class="background"
                          style="
                            height: 150px;
                            aspect-ratio: 16/9;
                            margin-left: auto;
                            margin-right: auto;
                            border-radius: 2px;
                            overflow: hidden;
                          "
                        >
                          <img
                            v-if="getSmallImagePath(lang.key)"
                            :src="getSmallImagePath(lang.key)"
                            class="ma-0 pa-0"
                            width="100%"
                            height="100%"
                            style="object-fit: contain"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </form>
</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';

import { languagesKeys, languagesKeysValue } from '@/config/config';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import UploadImage from '@/components/UploadManager/UploadImage.vue';
import { getImageURL } from '@/helper/cmsPaths';
import { getErrorDetails } from '@/helper/commonjs';

const initData = () => ({
  isLoading: false,
  // for languages tab
  tab: null,
  // for languages
  languagesKeysValue: languagesKeysValue(),
  formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
  // for main info
  // url: '',
  // icon: '',
  navType: null,
  navTypeId: null,
  // target: '',
  sortOrder: 0,
  targetItems: ['blank', 'self', 'parent', 'top'],
  urlType: {
    category: 'category',
    static: 'static',
    fullUrl: '',
  },
});

export default {
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
    'typeId',
    'parentId',
  ],
  components: {
    Autocomplete,
    UploadImage,
  },
  mixins: [validationMixin],
  validations: {
    // navType: { required },
    // url: { required },
    // target: { required },
    // icon: { required, maxLength: maxLength(50) },
  },
  data: () => ({ ...initData() }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error

      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }

      for (const k in this.$data.formLanguage) {
        const languageDetail = responseData.navigationLanguages.find(
          (x) => x.language == k,
        );
        if (languageDetail == undefined) continue;

        this.$data.formLanguage[k] = languageDetail;

        if (!languageDetail.url) {
          languageDetail.urlType = 'fullUrl';
          languageDetail.url = '';
        }
        const { url } = languageDetail;
        const [urlType, ...urlParts] = url.startsWith('/')
          ? url.split('/').slice(1)
          : ['fullUrl', url];

        this.$data.formLanguage[k].urlType = urlType;
        this.$data.formLanguage[k].url = urlParts.join('/');
      }
    } else {
      this.setDefaultValues();
    }
  },
  computed: {
    // validation
    // ----------------
    // urlErrors() {
    //   const errors = [];
    //   if (!this.$v.url.$dirty) return errors;
    //   !this.$v.url.required && errors.push('الرابط مطلوب');
    //   return errors;
    // },
    // iconErrors() {
    //   const errors = [];
    //   if (!this.$v.icon.$dirty) return errors;
    //   !this.$v.icon.required && errors.push('الحقل مطلوب');
    //   return errors;
    // },
    // targetErrors() {
    //   const errors = [];
    //   if (!this.$v.target.$dirty) return errors;
    //   !this.$v.target.required && errors.push('الحقل مطلوب');
    //   return errors;
    // },
    inputLanguages() {
      const languages = this.languagesKeysValue.reduce((p, v) => {
        const { key, value } = v;
        const data = this.formLanguage[key];
        const { name, url, urlType, image, target, icon } = data;

        const fullUrl = urlType == 'fullUrl' ? url : `/${urlType}/${url}`;
        if (!name) return p;
        return [
          ...p,
          { language: key, name, url: fullUrl, image, target, icon },
        ];
      }, []);
      return languages;
    },
  },
  methods: {
    getSmallImagePath(key) {
      return this.getImageSrc(this.formLanguage[`${key}`][`image`], 'sm');
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    showLoading(status) {
      this.isLoading = status;
    },
    doSave(postData) {
      this.showLoading(true);
      return this.$http
        .post('app/navigation', postData)
        .then((response) => {
          this.setItemID(response.data.id);
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.showLoading(false));
    },
    doUpdate(postData) {
      this.showLoading(true);
      return this.$http
        .put('app/navigation/' + this.itemId, { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.showLoading(false));
    },
    errorSaveHabdler(error) {
      const { message } = getErrorDetails(error);

      return showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    clearInput() {
      const _initData = initData();
      for (const k in this.$data) this.$data[k] = _initData[k];
      this.setDefaultValues();
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    async getById(id) {
      this.showLoading(true);
      return this.$http
        .get('app/navigation/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.showLoading(false));
    },
    doSubmit(cb) {
      if (this.isInvalid()) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }
      const parentId = this.parentId || null;
      const navTypeId = this.typeId;
      const sortOrder = this.sortOrder || '10000';

      const languages = this.inputLanguages;
      const postData = {
        parentId,
        navTypeId,
        sortOrder,
        navigationLanguages: languages,
      };
      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      } else {
        // TODO : AH Need Refactor

        if (this.itemId == parentId) {
          showSnackbarWarningMessage('قيمة القائمة الرئيسية غير مقبولة');
          return;
        }

        this.doUpdate(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      }
    },
    setDefaultValues() {
      this.languagesKeysValue.forEach(({ key }) => {
        this.formLanguage[`${key}`][`target`] = 'self';
        this.formLanguage[`${key}`][`urlType`] = 'fullUrl';
      });
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.setItemID(null);
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
  },
};
</script>

<style lang="scss">
.link-input {
  input {
    direction: ltr;
    text-align: left;
    unicode-bidi: bidi-override;
  }
}
</style>
