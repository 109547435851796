<template>
  <v-row>
    <v-col cols="12">
      <NavigationsTree
        :openToView="isView"
        :openToNew="false"
        :navTypeId="navTypeId || 0"
      />
    </v-col>
  </v-row>
</template>

<script>
import DefaultDialogCRUDDetails from '@/mixins/DefaultDialogCRUDDetails';
import NavigationsTree from '../components/NavigationsTree.vue';
export default {
  props: ['navTypeId'],
  mixins: [DefaultDialogCRUDDetails()],
  components: { NavigationsTree },
};
</script>

<style lang="scss" scoped></style>
